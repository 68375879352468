
















































































































































































































import { ResponseBatch, ResponseListBatch } from "@/models/interface-v2/batch.interface";
import Vue from "vue";
import moment from "moment";
import { DEFAULT_DATE_FORMAT } from "@/models/constant/date.constant";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { batchService } from "@/services-v2/batch.service";
import { ResponsePagination } from "@/models/interface/common.interface";
import { IRow } from "@/views/logistic/good-receipt/GoodReceiptChecklist.vue";
import { BATCH_STATUS } from "@/models/enums/batch.enum";
import { debounceProcess } from "@/helpers/debounce";
import { IOption } from "@/models/interface-v2/common.interface";
import { ResponseListMasterType } from "@/models/interface-v2/master.interface";
import { masterTypeService } from "@/services-v2/master-type.service";
export default Vue.extend({
  name: "ModalListQR",
  props: {
    propProductName: {
      type: String,
      default: "",
      required: false
    },
    propSupplierName: {
      type: String,
      default: "",
      required: false
    },
    propRowsDistinct: {
      type: Array,
      default: [] as IRow[],
      required: false
    }
  },
  data() {
    this.fillOptBarcodeNumber = debounceProcess(this.fillOptBarcodeNumber, 300);
    return {
      BATCH_STATUS,
      DEFAULT_DATE_FORMAT,
      dtListBatch: {} as ResponseListBatch,
      batchParam: {
        limit: 10,
        page: 0,
        search: "",
        sorts: "createdDate:desc",
        batchNumber: ""
      },
      loader: {
        table: false,
        barcodeNumber: false,
        initialProcess: false
      },
      formModel: {
        barcodeNumber: undefined as string | undefined,
        batchNumber: undefined as string | undefined,
        createdDate: undefined as string[] | undefined,
        initialProcess: undefined as string | undefined
      },
      formProps: {
        barcodeNumber: {
          label: "lbl_barcode_number",
          name: "barcode number",
          placeholder: "lbl_choose"
        },
        batchNumber: {
          label: "lbl_batch_number",
          name: "batch number",
          placeholder: "lbl_type_here"
        },
        createdDate: {
          label: "lbl_created_date",
          name: "created date",
          placeholder: "lbl_choose"
        },
        initialProcess: {
          label: "lbl_initial_process",
          name: "initial process",
          placeholder: "lbl_choose"
        },
      },
      dtListBarcodeNumber: {} as ResponseListBatch,
      dtOptions: {
        initialProcess: [] as IOption[]
      }
    };
  },
  computed: {
    formWrapper() {
      return {
        labelCol: {
          sm: {
            span: 24
          },
          md: {
            span: 6
          }
        },
        wrapperCol: {
          sm: {
            span: 24
          },
          md: {
            span: 12
          }
        }
      };
    }
  },
  mounted() {
    this.batchParam.search = this.buildQuerySearch();
    if (this.propRowsDistinct && this.propRowsDistinct.length) {
      const arrBatchNumber: string[] = [];
      this.propRowsDistinct.forEach((item: any) => {
        arrBatchNumber.push(item.batchNumber);
      });
      this.batchParam.batchNumber = arrBatchNumber.join(",");
    }
    this.fillTable(true);
    this.getListInitialProcess();
  },
  activated() {
    this.batchParam.search = this.buildQuerySearch();
    if (this.propRowsDistinct && this.propRowsDistinct.length) {
      const arrBatchNumber: string[] = [];
      this.propRowsDistinct.forEach((item: any) => {
        arrBatchNumber.push(item.batchNumber);
      });
      this.batchParam.search = this.buildQuerySearch();
      this.batchParam.batchNumber = arrBatchNumber.join(",");
    }
    if (!this.loader.table) this.fillTable(true);
  },
  methods: {
    moment,
    getListBatch(params: RequestQueryParamsModel): Promise<ResponseListBatch> {
      return batchService.listBatch(params);
    },
    getListMaster(name: string): Promise<ResponseListMasterType[]> {
      return masterTypeService.listMaster({ name });
    },
    async fillTable(flag = false): Promise<void> {
      try {
        this.loader.table = true;
        const res = await this.getListBatch(this.batchParam);
        res.data.forEach((el, i) => el.key = i.toString());
        this.dtListBatch = res;
        if (flag) this.dtListBarcodeNumber = res;
      } catch (error) {
        this.$message.error(this.$t("notif_process_fail").toString());
      } finally {
        this.loader.table = false;
      }
    },
    handleChoose(record: ResponseBatch): void {
      this.$emit("onChoose", record);
    },
    handleFilter(): void {
      const form = {...this.formModel};
      this.batchParam.page = 0;
      let search: string[] = [];
      if (form.barcodeNumber) search.push(`barcodeNumber~*${form.barcodeNumber}*`);
      if (form.batchNumber) search.push(`batchNumber~*${form.batchNumber}*`);
      if (form.initialProcess) search.push(`initialProcess~${form.initialProcess}`);
      if (form.createdDate && form.createdDate.length) {
        const start = this.moment(form.createdDate[0]).set({hour: 0, minute: 0, second: 0}).utcOffset("+07").format();
        const end = this.moment(form.createdDate[1]).set({hour: 23, minute: 59, second: 59}).utcOffset("+07").format();
        search.push(`createdDate>=${start}_AND_createdDate<=${end}`);
      }
      this.batchParam.search = search.join("_AND_");
      this.fillTable();
    },
    resetFilter(): void {
      this.batchParam.search = "";
      this.batchParam.page = 0;
      for (const key in this.formModel) {
        if (Object.prototype.hasOwnProperty.call(this.formModel, key)) {
          this.formModel[key] = undefined;
        }
      }
      this.fillTable();
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.batchParam.limit = response.size;
      this.batchParam.page = 0;
      this.fillTable();
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.batchParam.page = response.page - 1;
      this.fillTable();
    },
    buildQuerySearch(): string {
      const searchBy: string[] = [`status~${BATCH_STATUS.UNUSED}`];
      if (this.propProductName) searchBy.push(`product.name~*${this.propProductName}*`);
      if (this.propSupplierName) searchBy.push(`supplier.secureId~*${this.propSupplierName}*`);
      return searchBy.join("_AND_");
    },
    async fillOptBarcodeNumber(search = ""): Promise<void> {
      try {
        this.loader.barcodeNumber = true;
        const param: RequestQueryParamsModel = {
          page: 0,
          limit: 10,
          sorts: "createdDate:desc",
        };
        const searchBy: string[] = ["barcodeNumber!null"];
        if (search) searchBy.push(`barcodeNumber~*${search}*`);
        param.search = searchBy.join("_AND_");
        const res = await this.getListBatch(param);
        this.dtListBarcodeNumber = res;
      } catch (error) {
        this.$message.error(this.$t("notif_process_fail").toString());
      } finally {
        this.loader.barcodeNumber = false;
      }
    },
    async getListInitialProcess(): Promise<void> {
      try {
        this.loader.initialProcess = true;
        const res = await this.getListMaster("BATCH_INITIAL_PROCESS");
        this.dtOptions.initialProcess = res.map(x => {
          return { key: x.value, value: x.value };
        });
      } catch (error) {
        this.$message.error(this.$t("notif_process_fail").toString());
      } finally {
        this.loader.initialProcess = false;
      }
    },
  }
});
